import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import axios from 'axios'
import vco from "v-click-outside"
import VueApexCharts from 'vue-apexcharts'
import VueCompositionAPI from '@vue/composition-api'
import router from './router'
import store from '@/state/store'
import OtpInput from "@bachdgvn/vue-otp-input";
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import "@/assets/scss/app.scss";

window.axios = axios
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("key");
axios.defaults.headers.common["Content-Type"] = "application/json";

let token = localStorage.getItem("key");
if (token) {
  let userData = JSON.parse(localStorage.getItem("user"));
  if(userData) {
    axios
      .get(`/v1/check-auth-client?user_id=${userData.user_id}`)
      .catch((err) => {
        if (err) {
          if (err.message == 'Network Error') {
            localStorage.clear();
            router.push('/login')
            location.reload()
          }
          if (err.message == 'Request failed with status code 401') {
            localStorage.clear();
            router.push('/login')
            location.reload()
          }
        }
      });
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.message === 'Network Error') {
          localStorage.clear();
          router.push('/login')
          location.reload()
        }
        return Promise.reject(error)
      })
  } else {
    localStorage.clear();
    router.push('/login')
    location.reload()
  }
}

// window.onstorage = () => {
//   location.reload()
// };


Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(VueLoading)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(VueCompositionAPI)
Vue.component('apexchart', VueApexCharts)
Vue.component("v-otp-input", OtpInput);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
