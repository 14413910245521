import store from '@/state/store'
export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/Login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                  // Redirect to the home page instead
                  next({ name: 'home' })
              } else {
                  // Continue to the login page
                  next()
              }
            },
        },
    },
    // {
    //     path: '/register',
    //     name: 'register',
    //     component: () => import('../views/pages/Register'),
    //     meta: {
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             if (store.getters['auth/loggedIn']) {
    //                 // Redirect to the home page instead
    //                 next({ name: 'home' })
    //             } else {
    //                 // Continue to the login page
    //                 next()
    //             }
    //         },
    //     },
    // },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/Forgot-password'),
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/Index'),
        beforeEnter: (to, from, next) => {
            if (localStorage.key) {
              next()
            } else {
              next('/login')
            }
        }        
    },
    {
        path: '/send',
        name: 'send',
        component: () => import('../views/pages/Send'),
        beforeEnter: (to, from, next) => {
            if (localStorage.key) {
              next()
            } else {
              next('/login')
            }
        }        
    },
    {
        path: '/special-send',
        name: 'special-send',
        component: () => import('../views/pages/Special-Send'),
        beforeEnter: (to, from, next) => {
            if (localStorage.key) {
              next()
            } else {
              next('/login')
            }
        }        
    },
    {
        path: '/batch',
        name: 'batch',
        component: () => import('../views/pages/Batch'),
        beforeEnter: (to, from, next) => {
            if (localStorage.key) {
              next()
            } else {
              next('/login')
            }
        }        
    },
    {
        path: '/sender',
        name: 'sender',
        component: () => import('../views/pages/Sender'),
        beforeEnter: (to, from, next) => {
            if (localStorage.key) {
              next()
            } else {
              next('/login')
            }
        }        
    },
    {
        path: '/recipient',
        name: 'recipient',
        component: () => import('../views/pages/Recipient'),
        beforeEnter: (to, from, next) => {
            if (localStorage.key) {
              next()
            } else {
              next('/login')
            }
        }        
    },
    {
        path: '/transactions',
        name: 'transactions',
        component: () => import('../views/pages/Transaction'),
        beforeEnter: (to, from, next) => {
            if (localStorage.key) {
              next()
            } else {
              next('/login')
            }
        }        
    },
    {
        path: '/change-password',
        name: 'change.password',
        component: () => import('../views/pages/Change-password'),
        beforeEnter: (to, from, next) => {
            if (localStorage.key) {
              next()
            } else {
              next('/login')
            }
        }        
    },
    {
        path: '/balance',
        name: 'balance',
        component: () => import('../views/pages/Balance'),
        beforeEnter: (to, from, next) => {
            if (localStorage.key) {
              next()
            } else {
              next('/login')
            }
        }        
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/pages/Profile'),
        beforeEnter: (to, from, next) => {
            if (localStorage.key) {
              next()
            } else {
              next('/login')
            }
        }
    },
    {
        path: '/notification',
        name: 'notification',
        component: () => import('../views/pages/Notification'),
        beforeEnter: (to, from, next) => {
            if (localStorage.key) {
              next()
            } else {
              next('/login')
            }
        }
    },
    // {
    //     path: '/invoice/:id',
    //     name: 'invoice',
    //     component: () => import('../views/pages/Invoice'),
    //     beforeEnter: (to, from, next) => {
    //         if (localStorage.key) {
    //           next()
    //         } else {
    //           next('/login')
    //         }
    //     }
    // },
    {
        path: '/accounts',
        name: 'accounts',
        component: () => import('../views/pages/Accounts'),
        beforeEnter: (to, from, next) => {
            if (localStorage.key) {
              if(!JSON.parse(localStorage.getItem("user")).id){
                next()
              } else {
                next('/not-authorized')
              }
            } else {
              next('/login')
            }
        }
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../views/pages/notFound'),
        beforeEnter: (to, from, next) => {
          if (localStorage.key) {
            next()
          } else {
            next('/login')
          }
        }
    },
    {
        path: '/not-authorized',
        name: 'not-authorized',
        component: () => import('../views/pages/notAuth'),
        beforeEnter: (to, from, next) => {
          if (localStorage.key) {
            next()
          } else {
            next('/login')
          }
        }
    },
    // {
    //     path: '/contact-us',
    //     name: 'contact-us',
    //     component: () => import('../views/pages/contact-us'),
    //     beforeEnter: (to, from, next) => {
    //         if (localStorage.key) {
    //           next()
    //         } else {
    //           next('/login')
    //         }
    //     }
    // },
    {
        path: '/otp-login',
        name: 'otp',
        component: () => import('../views/pages/otp'),
        beforeEnter: (to, from, next) => {
            if (localStorage.scan) {
              next()
            } else {
              next('/login')
            }
        }
    },
    {
        path: '/*',
        redirect: '/404',
    },
]
